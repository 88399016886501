import {useState, useEffect} from "react";
import {Container, Row, Col} from "react-bootstrap";
import headerImg from "../assets/img/header-img.svg";
import {ArrowRightCircle} from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Banner = () => {
    const [loopNum, setLoopNum] = useState(0);
    const [isDeleting, setIsDeleting] = useState(false);
    const [text, setText] = useState('');
    const [delta, setDelta] = useState(250 - Math.random() * 100);
    const [index, setIndex] = useState(1);
    const toRotate = ["Frontend Developer", "Backend Developer", "Fullstack engineer (for real)"];

    useEffect(() => {
        let ticker = setInterval(() => {
            tick();
        }, delta);

        return () => {
            clearInterval(ticker)
        };
    }, [text])

    const tick = () => {
        let i = loopNum % toRotate.length;
        let fullText = toRotate[i];
        let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

        setText(updatedText);

        if (isDeleting) {
            setDelta(100);
        }

        if (!isDeleting && updatedText === fullText) {
            setIsDeleting(true);
            setIndex(prevIndex => prevIndex - 1);
            setDelta(150);
        } else if (isDeleting && updatedText === '') {
            setIsDeleting(false);
            setLoopNum(loopNum + 1);
            setIndex(1);
            setDelta(150);
        } else {
            setIndex(prevIndex => prevIndex + 1);
        }
    }

    return (
        <section className="banner" id="home">
            <Container>
                <Row className="aligh-items-center">
                    <Col xs={12} md={6} xl={7}>
                        <TrackVisibility>
                            {({isVisible}) =>
                                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                                    <span className="tagline">Welcome to my Portfolio</span>
                                    <h1 className="rotating">Hi! I'm Ricardo a&nbsp;
                                        <span className="txt-rotate"><span
                                            className="wrap">{text}</span></span>
                                    </h1>
                                    <p>
                                        I am engineer with {(new Date().getFullYear()) - 2014} years of experience. I
                                        have developed many
                                        full-stack web applications from scratch like e-commerce
                                        sites, Software as a Service (SASS) products.
                                        <br/>
                                        I have also worked with many companies over the years, solving many interesting
                                        challenges such as:
                                    </p>
                                    <ul>
                                        <li>Rewriting <b>real-time chat web</b> applications</li>
                                        <li>Helped <b>TWJ </b> (The Wall Street Journal) rewrite their news article
                                            reader in React
                                        </li>
                                        <li>Developed an entire microservices architecture for a finance company (by
                                            myself) in NodeJS
                                        </li>
                                        <li>Ported old APIs to Go (Golang); making them an order of magnitude faster
                                            while handling 20X the payload
                                        </li>
                                        <li>And much more</li>
                                    </ul>

                                    <a href="#connect">Let’s Connect <ArrowRightCircle size={25}/></a>
                                </div>}
                        </TrackVisibility>
                    </Col>
                    <Col xs={12} md={6} xl={5}>
                        <TrackVisibility>
                            {({isVisible}) =>
                                <div className={isVisible ? "animate__animated animate__zoomIn" : ""}>
                                    <img src={headerImg} alt="Header Img"/>
                                </div>}
                        </TrackVisibility>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
