import {Container, Row, Col} from "react-bootstrap";
import linkedin from "../assets/img/linkedin.svg";
import github from '../assets/img/github.svg';

export const Footer = () => {
    return (
        <footer className="footer">
            <Container>
                <Row className="align-items-center">
                    <Col size={12} className="text-center">
                        <div className="social-icon">
                            <a href="https://www.linkedin.com/in/ricardofabila" target="_blank" rel="noopener noreferrer"><img src={linkedin} alt="" /></a>
                            <a href="https://github.com/ricardofabila" target="_blank" rel="noopener noreferrer"><img src={github} alt="" /></a>
                        </div>
                        <p>
                            Copyright &copy; {new Date().getFullYear()} | Ricardo Fabila
                        </p>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}
