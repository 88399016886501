import {Container, Row, Col, Tab, Nav} from "react-bootstrap";
import TrackVisibility from 'react-on-screen';
import {ProjectCard} from "./ProjectCard";
import 'animate.css';
import crew from "../assets/img/projects/crew/crew.png";
import finbe from "../assets/img/projects/finbe/finbe.png";
import bishop from "../assets/img/projects/bishop/bishop.jpg";
import twsj from "../assets/img/projects/twsj/twsj.png";
import ritani from "../assets/img/projects/ritani/ritani.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import mario from "../assets/img/projects/mario/thumbnail_mini.jpg";
import caja from "../assets/img/projects/don-caja/thumbnail_mini.jpg";
import converter from "../assets/img/projects/converter/thumbnail_mini.jpg";
import writer from "../assets/img/projects/writer/thumbnail_mini.jpg";
import mayan from "../assets/img/projects/mayanfruits/thumbnail_mini.jpg";
import airtm from "../assets/img/projects/airtm/thumbnail_mini.jpg";
import hive from "../assets/img/projects/hivefood/thumbnail_mini.jpg";
import nrg from "../assets/img/projects/nrg/thumbnail_mini.jpg";
import pesca from "../assets/img/projects/pesca/thumbnail_mini.jpg";

export const Projects = () => {
    return (
        <section className="project" id="projects">
            <Container>
                <Row>
                    <Col size={12}>
                        <TrackVisibility>
                            {({isVisible}) =>
                                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                                    <h2>Projects</h2>
                                    <p>Here a just a few of the apps I've developed.</p>
                                    <Tab.Container id="projects-tabs" defaultActiveKey="first">
                                        <Nav variant="pills"
                                             className="nav-pills mb-5 justify-content-center align-items-center"
                                             id="pills-tab">
                                            <Nav.Item>
                                                <Nav.Link eventKey="first">Company work</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="second">Personal projects</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="third">Freelance stuff</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                        <Tab.Content id="slideInUp"
                                                     className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                                            <Tab.Pane eventKey="first">
                                                <p>These are a few companies I have worked with.</p>
                                                <Row>
                                                    <ProjectCard
                                                        title="BishopFox"
                                                        link="/project/bishopfox"
                                                        description="Cosmos (formerly CAST) proactively defends dynamic attack surfaces by combining advanced technology, automation, and expert-driven testing to continuously identify and remediate high-risk exposures."
                                                        imgUrl={bishop}
                                                    />
                                                    <ProjectCard
                                                        title="Crew app"
                                                        link="/project/crew"
                                                        description="Crew Enterprise connects your entire workforce from the frontline to corporate leadership, unifying communications, streamlining scheduling, elevating task execution, and making operations human."
                                                        imgUrl={crew}
                                                    />
                                                    <ProjectCard
                                                        title="The Wall Street Journal"
                                                        link="https://www.wsj.com/"
                                                        description="The Wall Street Journal is an American business-focused, international daily newspaper. I helped them rebuild their news article reader in NextJS."
                                                        imgUrl={twsj}
                                                    />
                                                    <ProjectCard
                                                        title="FinBe"
                                                        link="https://www.finbe.com.mx/"
                                                        description="FinBe is a financial company that focuses on giving people credit for cars, houses, and staring businesses. I developed an easy to use API using a microservices architecture for their partners to do things like: get insurance quotations, find credit scores, and much more."
                                                        imgUrl={finbe}
                                                    />
                                                    <ProjectCard
                                                        title="Ritani"
                                                        link="https://www.ritani.com/"
                                                        description="Online store that makes it really simple to purchase high-quality, handcrafted jewelry. Lead my team to develop a new product all the way from conception to production. The project was a web app that would help a diamond business give quotations to people looking to sell their diamonds."
                                                        imgUrl={ritani}
                                                    />
                                                </Row>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="second">
                                                <p>People really seem to dig these projects</p>
                                                <Row>
                                                    <ProjectCard
                                                        title="Mario Genetic Algorithm"
                                                        description="Genetic Algorithm for beating the first level of Super Mario"
                                                        imgUrl={mario}
                                                        link="/project/mario"
                                                    />
                                                    <ProjectCard
                                                        title="Don Caja"
                                                        description="Web app to register transactions into a handmade BlockChain"
                                                        imgUrl={caja}
                                                        link="/project/doncaja"
                                                    />
                                                    <ProjectCard
                                                        title="Simple unit converter"
                                                        description="A nice and straight to the point unit converter"
                                                        imgUrl={converter}
                                                        link="/project/simple-unit-converter"
                                                    />
                                                    <ProjectCard
                                                        title="Focus Writer"
                                                        description="A simple web based text editor for your writer within"
                                                        imgUrl={writer}
                                                        link="/project/focus-writer"
                                                    />
                                                </Row>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="third">
                                                <p>
                                                    Some freelance work I did back in the day
                                                    <br/>
                                                    (mostly to pay my way though college 🤪).
                                                </p>
                                                <Row>
                                                    <ProjectCard
                                                        title="Mayan Fruits"
                                                        description="Ecommerce from scratch to order groceries and healthy food"
                                                        imgUrl={mayan}
                                                        link="/project/mayan-fruits"
                                                    />
                                                    <ProjectCard
                                                        title="Airtm"
                                                        description="Use your local currency to access dollars as if you were in the US"
                                                        imgUrl={airtm}
                                                        link="/project/airtm"
                                                    />
                                                    <ProjectCard
                                                        title="Hive food"
                                                        description="An online web app for ordering food online"
                                                        imgUrl={hive}
                                                        link="/project/hive-food"
                                                    />
                                                    <ProjectCard
                                                        title="NRG Solutions"
                                                        description="A dynamic website for a solar panel installation business"
                                                        imgUrl={nrg}
                                                        link="/project/nrg"
                                                    />
                                                    <ProjectCard
                                                        title="Pesca Cozumel"
                                                        description="Website for local business to rent boats in Cozumel, Mexico    "
                                                        imgUrl={pesca}
                                                        link="/project/pesca-cozumel"
                                                    />
                                                </Row>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Tab.Container>
                                </div>}
                        </TrackVisibility>
                    </Col>
                </Row>
            </Container>
            <img className="background-image-right" src={colorSharp2}></img>
        </section>
    )
}
