import {Container, Row, Col} from "react-bootstrap";
import contactImg from "../assets/img/contact-img.svg";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Contact = () => {
    return (
        <section className="contact" id="connect">
            <Container>
                <Row className="align-items-center">
                    <Col size={12} lg={6}>
                        <TrackVisibility>
                            {({isVisible}) =>
                                <img className={isVisible ? "animate__animated animate__zoomIn" : ""} src={contactImg}
                                     alt="Contact Us"/>
                            }
                        </TrackVisibility>
                    </Col>
                    <Col size={12} lg={6}>
                        <TrackVisibility>
                            {({isVisible}) =>
                                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                                    <h2>Get In Touch</h2>
                                    <p>You can send me a message on <a href="https://www.linkedin.com/in/ricardofabila"
                                                                       style={{color: "white"}}
                                                                       target="_blank"
                                                                       rel="noopener noreferrer">linkedIn</a>.
                                    </p>
                                </div>
                            }
                        </TrackVisibility>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
