import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import colorSharp from "../assets/img/color-sharp.png"
import {SkillProgress} from "./SkillProgress";

export const Skills = () => {
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: {max: 4000, min: 3000},
            items: 5
        },
        desktop: {
            breakpoint: {max: 3000, min: 1024},
            items: 3
        },
        tablet: {
            breakpoint: {max: 1024, min: 464},
            items: 2
        },
        mobile: {
            breakpoint: {max: 464, min: 0},
            items: 1
        }
    };

    return (
        <section className="skill" id="skills">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="skill-bx wow zoomIn">
                            <h2>Skills</h2>
                            <p>
                                This is a small list of the things I know how to do and for how long.
                            </p>
                            <Carousel responsive={responsive} infinite={true}
                                      className="owl-carousel owl-theme skill-slider">
                                <div className="item">
                                    <SkillProgress progress={(new Date().getFullYear()) - 2014}/>
                                    <br/><br/>
                                    <h5>JavaScript</h5>
                                </div>
                                <div className="item">
                                    <SkillProgress progress={(new Date().getFullYear()) - 2016}/>
                                    <br/><br/>
                                    <h5>TypeScript</h5>
                                </div>
                                <div className="item">
                                    <SkillProgress progress={(new Date().getFullYear()) - 2014}/>
                                    <br/><br/>
                                    <h5>NodeJS</h5>
                                </div>
                                <div className="item">
                                    <SkillProgress progress={(new Date().getFullYear()) - 2016}/>
                                    <br/><br/>
                                    <h5>React</h5>
                                </div>
                                <div className="item">
                                    <SkillProgress progress={(new Date().getFullYear()) - 2016}/>
                                    <br/><br/>
                                    <h5>Docker</h5>
                                </div>
                                <div className="item">
                                    <SkillProgress progress={(new Date().getFullYear()) - 2014}/>
                                    <br/><br/>
                                    <h5>SQL <br/> (MySQL and Postgresql)</h5>
                                </div>
                                <div className="item">
                                    <SkillProgress progress={(new Date().getFullYear()) - 2016}/>
                                    <br/><br/>
                                    <h5>NoSQL <br/> (Mongo, DynamoDB)</h5>
                                </div>
                                <div className="item">
                                    <SkillProgress progress={(new Date().getFullYear()) - 2014}/>
                                    <br/><br/>
                                    <h5>PHP <br/> (don't like to do it)</h5>
                                </div>
                                <div className="item">
                                    <SkillProgress progress={(new Date().getFullYear()) - 2014}/>
                                    <br/><br/>
                                    <h5>Laravel</h5>
                                </div>
                                <div className="item">
                                    <SkillProgress progress={(new Date().getFullYear()) - 2018}/>
                                    <br/><br/>
                                    <h5>Go (golang)</h5>
                                </div>
                                <div className="item">
                                    <SkillProgress progress={(new Date().getFullYear()) - 2016}/>
                                    <br/><br/>
                                    <h5>AWS</h5>
                                </div>
                                <div className="item">
                                    <SkillProgress progress={(new Date().getFullYear()) - 2016}/>
                                    <br/><br/>
                                    <h5>ELM <br/> (think Haskell but actually usefull)</h5>
                                </div>
                                <div className="item">
                                    <SkillProgress progress={(new Date().getFullYear()) - 2016}/>
                                    <br/><br/>
                                    <h5>Clojure/ClojureScript</h5>
                                </div>
                            </Carousel>
                            <p style={{marginTop: "50px", marginBottom: "0"}}>
                                Plus many more things, like Apache, NGINX, Linux, Lua, Python, Mathlab, Julia, etc...
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <img className="background-image-left" src={colorSharp}/>
        </section>
    )
}
