import {Col} from "react-bootstrap";

export const ProjectCard = ({title, link, description, imgUrl}) => {
    return (
        <Col size={12} sm={6} md={4}>
            <a href={link}>
                <div className="proj-imgbx">
                    <img src={imgUrl} style={{maxHeight: "320px", maxWidth: "440px", "objectFit": "contain"}}/>
                    <div className="proj-txtx">
                        <h4>{title}</h4>
                        <span>{description}</span>
                    </div>
                </div>
            </a>
        </Col>
    )
}
